.container {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

label {
  font-weight: 500;
  font-size: 1rem;
}

input {
  border: 1px solid #cccccc;
  outline: none;
  padding: 10px 12px;
  font-size: 1rem;
  border-radius: 5px;
}

input:hover {
  border: 1px solid #adadad;
}

input:focus {
  border: 1px solid #239ce2;
}
